.insurance-cantainer {
  background: #f1fdff;
  padding: 20px 15px;
  text-align: center;
  justify-content: center;
}
h1 {
  font-size: 50px;
}
.cantainer {
  display: flex;
  justify-content: center;
}
.cantainer {
  display: flex;
  justify-content: center;
}
.box-info {
  /* border: 1px solid black; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* align-items: center; */
  text-align: start;
  margin: auto;
  width: 90%;
  background-color: #fff;
}
.box-1 {
  width: 100%;
  /* border: 1px solid black; */
  font-size: 15px;
  padding: 20px;
  margin: 10px;
}
.box-1 h2 {
  margin: 10px;
  padding: 10px;
  color: #000;
}
.box-1 p {
  margin: 10px;
  padding: 10px;
  text-align: justify;
  font-size: larger;
}
.box-2 {
  width: 100%;
  /* border: 1px solid black;   */
}
.box-2 img {
  width: 90%;
  /* border: 1px solid black;   */
  padding: 0;
  margin: 0;
}

.vision-info {
  display: flex;
  justify-content: center;
  text-align: center;
  /* border: 1px solid black; */
  width: 100%;
}
.vision-box {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.img-box {
  /* border: 1px solid black; */
  width: 100%;
}
.img-box img {
  /* border: 1px solid black;  */
  max-width: 80%;

  /* images css ------------------ */
}
.premium-insurance {
  margin-top: 8%;
}
.spouse-insurance {
  margin-top: 2%;
}
.Nri-investment {
  margin-top: 3%;
}
.group-health {
  margin-top: 8%;
}
.investmentPlan {
  margin-top: 8%;
}
.homeinsurance {
  margin-top: 4%;
}
.child {
  margin-top: 5%;
}
.senior-Citizen {
  margin-top: 4%;
}
.corona {
  margin-top: 7%;
}
.capitalimg {
  margin-top: 10%;
}
.familyimg {
  margin-top: 10%;
}
.familyimg img {
  width: 115%;
}
.cyber {
  margin-left: 20px;
}

.cyber img {
  width: 80%;
  margin-top: 30px;
}
.vision-box1 {
  width: 100%;
  margin-top: 3%;
}

.vision-box1 p {
  color: #4f4f4f;
  font-family: Mulish;
  font-size: large;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-align: justify;
}
li ol {
  text-align: justify;
  font-size: large;
}

.vision-box1 h2 {
  display: flex;
  justify-content: flex-start;
  text-align: start;
  /* margin: 10px; */
}
.btn-cantainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: #00b0cb;
  margin-top: 20px;
}
.btn-1 {
  width: 40%;
  color: #fff;
  margin: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.btn-1 h2 {
  font-weight: 300;
  font-size: 2.5rem;
}
.btn-2 {
  width: 35%;
}
.btn-1 h1 {
  font-weight: 400;
  font-size: 30px;
  margin-top: 40px;
}
.btn-info {
  margin-top: 20px;
  width: 100%;
  background: none;
}
.btn-info:hover {
  background: none;
}
.btn-info button {
  margin: 0 10px 0 0;
  padding: 8px 25px;
  background-color: white;
  color: #00b0cb;
  font-family: Montserrat;
  font-size: 22px;
  border-radius: 5px;
  border: none;
}
.btn-info button:hover {
  color: white;
  background-color: #000;
}

.investment-cantainer {
  background: #f1fdff;
  padding: 45px 15px;
  text-align: center;
  justify-content: center;
}
.investment-cantainer img {
  width: 100px;
}
.investment-cantainer span {
  font-size: 2rem;
  font-weight: 700;
}
#box-img {
  margin-top: 30%;
  width: 100%;
}

@media (max-width: 992px) {
  .investment-cantainer {
    font-size: inherit;
    flex-wrap: wrap;
  }
  .investment-cantainer h1 {
    font-size: 30px;
    flex-wrap: wrap;
  }

  .cantainer {
    width: 100%;
    flex-wrap: wrap;
  }
  .box-info {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .vision-box {
    display: flex;
    flex-direction: column-reverse;
    /* flex-direction: column; */
  }
  .vision-box1 {
    text-align: justify;
  }
  .btn-info button {
    margin: 5px;
    font-size: small;
    padding: 6px 10px;
  }
  .btn-1 h2 {
    font-size: large;
  }
  .btn-1 h1 {
    font-size: large;
  }
}
@media (max-width: 600px) {
  .btn-info {
    display: flex;
    /* padding: 6px 20px; */
    font-size: small;
  }
  .btn-info button {
    margin: 5px;
    flex-direction: row;
    font-size: 10px;
  }
}
