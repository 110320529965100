.shimmer-outer {
    width:100%;
    height: 100%;
    overflow: hidden;
  }
  
  .shimmer {
    position: relative;
    width: 100%;
    /* height: 100%; */
    background: #f0f0f0;
    margin-bottom: 1rem;
    border-radius: 15px;
    overflow: hidden;
  }
  
  .shimmer-inner {
    height: 15rem;
    animation: pulse 1.5s infinite;
    /* padding-left: 40px; */
    padding-top: 65px;
    text-align: center;
    font-size: large;
    font-weight: 700;
  }
  
  @keyframes pulse {
    0% {
      background-color: #f0f0f0;
    }
    50% {
      background-color: #e0e0e0;
    }
    100% {
      background-color: #f0f0f0;
    }
  }
  